#gallery {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-auto-flow: row;
    gap: 1rem;

    padding: 1rem;
    min-height: 24rem;

    &.dragging {
        border-width: 4px;
        border-style: solid;
        border-radius: 0.5rem;
        border-color: rgb(52 211 153);
        background-color: rgb(236 253 245);
    }

    & > div:not(.request) {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        align-items: flex-start;

        & img {
            width: 100%;
        }

        & div {
            font-size: 0.75rem; /* 12px */
            line-height: 1rem; /* 16px */
            padding-left: 0.25rem; /* 4px */
            padding-right: 0.25rem; /* 4px */
            margin-top: 0.25rem; /* 4px */
            margin-bottom: 0.25rem; /* 4px */
            color: var(--gray-600);
        }
    }

    & > div.request {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        border-width: 1px;
        border-style: solid;
        border-color: var(--gray-300);
        border-radius: 0.25rem;
        background-color: rgb(255 255 255);
        align-items: center;
        justify-content: center;
        height: 6rem;

        & span {
            font-size: 0.75rem; /* 12px */
            color: var(--gray-500);

        }
    }

    & .highlight {
        border-width: 8px;
        border-style: solid;
        border-radius: 0.5rem;
        border-color: rgb(96 165 250);
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        background-color: rgb(219 234 254);
    }

}

#gallery:not(.dragging) {
        box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
        background-color: rgb(248 250 252);
}
