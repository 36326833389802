#risk-analysis-grid {
    /*display: grid;*/
    /*grid-template-columns: 1.44fr 98px 1fr 98px 1fr 98px 1fr 98px 1fr;*/
    overflow-y: scroll;
    max-height: calc(100vh - 280px);

    .ra-fn-details {
        display: grid;
        grid-template-columns: 36px 1fr 36px 1fr 98px 1fr 66px 1fr 66px 66px;
    }

    p {
        margin: 0;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .ra-bottom-toolbar {
        position: absolute;
        bottom: calc(0% + 1px);
        right: calc(0% + 16px);
    }

    .ra-basis-20 {
        flex-basis: 20%;
    }

    .ra-basis-65 {
        flex-basis: 65%;
    }

    .ra-basis-15 {
        flex-basis: 15%;
    }
}

.ra-groups-collapsed {
    writing-mode: vertical-lr;
    text-orientation: upright;
}

.ra-add-comment {
    width: 256px;
    font-size: 0.75rem;
}

.ra-edit-comment {
    width: 100%;
    font-size: 0.75rem;
}

.ra-comment-btn {
    border: none;
    background-color: transparent;
    width: 16px;
    height: 16px;
    padding: 0;
    cursor: pointer;
    color: var(--gray-500);
    margin-left: 4px;
    margin-bottom: 4px;
}

.ra-comment {
    font-size: 0.75rem;
    margin-top: 4px;
    border: 1px solid var(--gray-400);
    background-color: var(--gray-50);
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
}

.ra-ai-badge {
    position: absolute;
    font-size: 0.6rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--blue-50);
    background-color: var(--blue-500);
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    top: 2px;
    right: 14px;
}

.ra-severity {
    right: -2.5rem;
    bottom: calc(50% + .5rem);
}
