.spec-matching {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: .75rem;

    & .spec-detail {
        grid-column: span 2 / span 2;
    }

    & .tile {
        border: 1px solid var(--gray-300);
        min-height: 16rem;
        border-radius: .25rem;
        padding-left: .5rem;
        padding-right: .5rem;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    }

    & .content {
        height: calc(100vh - 28rem);
        overflow: auto;
    }

    & .title {
        margin-top: 2px;
        margin-bottom: 2px;
        font-size: .75rem;
        line-height: 1rem;
        font-weight: 700;
    }

    & .header {
        height: 18rem;
        overflow: auto;
    }

    & img {
        width: 100%;
    }
}
