.ProseMirror:focus-visible {
    outline: none;
}

.tiptap {
    :first-child {
        margin-top: 0;
    }

    /* List styles */
    ul,
    ol {
        padding: 0 1rem;
        margin: 1.25rem 1rem 1.25rem 0.4rem;

        li p {
            margin-top: 0.25em;
            margin-bottom: 0.25em;
        }
    }

    /* Task list specific styles */
    ul[data-type="taskList"] {
        list-style: none;
        margin-left: 0;
        padding: 0;

        li {
            align-items: flex-start;
            display: flex;

            > label {
                flex: 0 0 auto;
                margin-right: 0.5rem;
                user-select: none;
            }

            > div {
                flex: 1 1 auto;
            }
        }

        input[type="checkbox"] {
            cursor: pointer;
        }

        ul[data-type="taskList"] {
            margin: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.1;
        margin-top: 2.5rem;
        text-wrap: pretty;
    }

    h1,
    h2 {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem;
    }

    h1 {
        font-size: 1.4rem; 
    }

    h2 {
        font-size: 1.2rem; 
    }

    h3 {
        font-size: 1.1rem; 
    }

    h4,
    h5,
    h6 {
        font-size: 1rem; 
    }

    a {
        color: #6b21a8;
        cursor: pointer;

        &:hover {
            color: #3b0764;
        }
    }

    blockquote {
        border-left: 3px solid var(--gray-400);
        background-color: var(--gray-50);
        padding-left: 1rem;
        margin: .5rem 0;
        font-style: italic;
        font-weight: 200;
    }

    /* Table-specific styling */
    table {
        border-collapse: collapse;
        margin: 0;
        overflow: hidden;
        table-layout: fixed;
        width: 100%;

        td,
        th {
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            min-width: 1em;
            padding: 6px 8px;
            position: relative;
            vertical-align: top;

            > * {
                margin-bottom: 0;
            }
        }

        th {
            background-color: #e1e1e1;
            font-weight: bold;
            text-align: left;
        }

        .selectedCell:after {
            background: #e1e1e1;
            opacity: 0.5;
            content: "";
            left: 0; right: 0; top: 0; bottom: 0;
            pointer-events: none;
            position: absolute;
            z-index: 2;
        }

        .column-resize-handle {
            background-color: purple;
            bottom: -2px;
            pointer-events: none;
            position: absolute;
            right: -2px;
            top: 0;
            width: 4px;
        }
    }

    .tableWrapper {
        margin: 1.5rem 0;
        overflow-x: auto;
    }

    &.resize-cursor {
        cursor: ew-resize;
        cursor: col-resize;
    }

    img {
        display: block;
        height: auto;
        margin: 1.5rem 0;
        max-width: 100%;

        &.ProseMirror-selectednode {
            outline: 3px solid purple;
        }
    }

    .mention {
        color: var(--blue-600);
        font-weight: 600;
    }

    p.is-editor-empty:first-child::before {
        color: var(--gray-400);
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }
}
