@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-cell {
  outline: none !important;
}

textarea {
    font-family: 'Roboto';
}

td p {
  margin: 0;
  padding: 0;
}

del, ins {
    text-decoration: none;
}

ins {
    background-color: #d4fcbc;
}

del {
    background-color: #fbb;
}

.full-height {
    height: calc(100vh - 100px);
}

:root {
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --gray-900: #111827;
    --gray-950: #030712;

    --slate-50:  #f8fafc;
    --slate-100: #f1f5f9;
    --slate-200: #e2e8f0;
    --slate-300: #cbd5e1;
    --slate-400: #94a3b8;
    --slate-500: #64748b;
    --slate-600: #475569;
    --slate-700: #334155;
    --slate-800: #1e293b;
    --slate-900: #0f172a;
    --slate-950: #020617;

    --green-50:  #f0fdf4;
    --green-100: #dcfce7;
    --green-200: #bbf7d0;
    --green-300: #86efac;
    --green-400: #4ade80;
    --green-500: #22c55e;
    --green-600: #16a34a;
    --green-700: #15803d;
    --green-800: #166534;
    --green-900: #14532d;
    --green-950: #052e16;

    --blue-50:  #eff6ff;
    --blue-100: #dbeafe;
    --blue-200: #bfdbfe;
    --blue-300: #93c5fd;
    --blue-400: #60a5fa;
    --blue-500: #3b82f6;
    --blue-600: #2563eb;
    --blue-700: #1d4ed8;
    --blue-800: #1e40af;
    --blue-900: #1e3a8a;
    --blue-950: #172554;

    --purple-50:  ##faf5ff;
    --purple-100: ##f3e8ff;
    --purple-200: ##e9d5ff;
    --purple-300: ##d8b4fe;
    --purple-400: ##c084fc;
    --purple-500: ##a855f7;
    --purple-600: ##9333ea;
    --purple-700: ##7e22ce;
    --purple-800: ##6b21a8;
    --purple-900: ##581c87;
    --purple-950: ##3b0764;
}
