#urs-diff-grid {
    display: grid;
    grid-template-columns: 96px 96px 1fr 1fr 96px 96px 128px;

    & div {
        padding: 4px;
    }

    & p {
        margin: 0;
    }

    .vertical {
        writing-mode: vertical-lr;
        /*text-orientation: upright;*/
    }
}
